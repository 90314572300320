const chartOptions = (minVal, maxVal) => {
  const max = maxVal + 0.32 * (maxVal - minVal);
  const min = minVal - 0.02 * (maxVal - minVal);

  return {
    chart: {
      type: 'line',
      toolbar: {
        tools: {
          download: false,
        },
      },
      sparkline: {
        enabled: true,
      },
    },
    colors: ['rgb(112,172,192)', 'rgb(0,223,139)', 'rgb(254,167,24)', 'rgb(255,78,107)', 'rgb(108,83,202)'],
    stroke: {
      show: true,
      curve: 'smooth',
      width: 2,
      dashArray: 0,
    },
    fill: {
      opacity: 0,
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: '#ddd',
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    yaxis: {
      max,
      min,
    },
  };
};


export default chartOptions;
