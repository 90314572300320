import moment from 'moment';
import substractDays from '@/utils/substractDays';

const today = moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DD');
const yesterday = moment(new Date(substractDays(1).setHours(0, 0, 0, 0))).format('YYYY-MM-DD');
const startOfTheWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
const startOfTheMonth = moment().startOf('month').format('YYYY-MM-DD');

export default {
  computed: {
    customPeriodOptions() {
      return [
        {
          id: 'day',
          label: this.$t('calendar.day'),
          value: {
            start: yesterday,
            end: today,
          },
        },
        {
          id: 'week',
          label: this.$t('calendar.week'),
          value: {
            start: startOfTheWeek,
            end: today,
          },
        },
        {
          id: 'month',
          label: this.$t('calendar.month'),
          value: {
            start: startOfTheMonth,
            end: today,
          },
        },
        {
          id: 'period',
          label: this.$t('calendar.forPeriod'),
          type: 'range',
          value: {
            start: startOfTheWeek,
            end: today,
          },
        },
      ];
    },
  },
};
