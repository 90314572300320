<template>
  <div class="finances-page pb-3">
    <div class="controls-row row mb-4">
      <div class="col finances-page__select-range">
        <CustomSelect
          type="radiogroup"
          :disabled="isLoading"
          :options="customPeriodOptions"
          :selected.sync="selectedRange"
        />
      </div>
    </div>
    <Pane>
      <div class="row">
        <Loader :visible="isChartLoading" />
        <div class="col-8">
          <div class="chart-controls">
            <CustomSelect
              type="select"
              :options="earningsChartOptions"
              :selected.sync="earningsConfig.metric"
              v-on:update:selected="loadEarningsChart()"
            />
          </div>
          <apexchart
            type="line"
            height="200"
            :options="
              chartOptions(findMin(sortedCharts), findMax(sortedCharts))
            "
            :series="sortedCharts"
          />
        </div>
        <div class="col-4">
          <div v-if="getTotals" class="total-stats">
            <div class="stat-total">
              {{ earningsConfig.metric.label }}
              &mdash;
              {{ formatNumberCompact(getTotals[earningsConfig.metric.value]) }}
              {{ !isChartLoading ? getChartMetric.suffix : "" }}
            </div>
          </div>
          <div class="chart-legend">
            <div
              v-for="(element, index) in earningsLegend"
              :key="index"
              class="chart-legend__element"
            >
              <span
                class="chart-legend__marker"
                :style="`background-color: ${
                  chartOptions(findMin(sortedCharts), findMax(sortedCharts))
                    .colors[index]
                }`"
              />
              {{
                element.name ||
                element.url ||
                countries[element.country] ||
                $t('common.allWorld')
              }}
            </div>
          </div>
        </div>
      </div>
    </Pane>
    <Pane class="mt-4">
      <CustomSelect
        v-if="
          !isSubIdsLoading &&
          isSubIds &&
          !shownDetail &&
          earningsConfig.group !== 'country'
        "
        type="select"
        class="ml-1 mb-3"
        :options="subIdsOptions"
        :selected.sync="subId"
        v-on:update:selected="loadEarnings()"
      />
      <Loader :visible="isLoading" />
      <DataGrid
        :data="getEarnings"
        :model="earningsModel"
        :detailedRequestConfig="detailedRequestConfig"
        count-total
        expansible
        v-on:update:col="
          earningsConfig.group = $event.value.group || $event.value.field;
          loadEarnings();
        "
        v-on:update:detailShown="onHostDetailShown"
      />
    </Pane>
  </div>
</template>

<script>
import CustomSelect from '@/components/controls/CustomSelect.vue';
import DataGrid from '@/components/decorative/DataGrid.vue';
import Loader from '@/components/decorative/Loader.vue';
import Pane from '@/components/decorative/Pane.vue';
import chartOptions from '@/configs/chart-options/earningsChart';
import customPeriodOptions from '@/configs/customPeriodOptions';
import earningsModel from '@/configs/table-models/earnings';
import detailedModels from '@/configs/table-models/earningsDetailed';
import countries from '@/strings/countries';
import moment from 'moment';
import formatNumberCompact from '@/utils/numberFormat';

export default {
  name: 'Payouts',
  mixins: [customPeriodOptions, earningsModel, detailedModels, countries],
  components: {
    Pane,
    DataGrid,
    Loader,
    CustomSelect,
  },
  data() {
    return {
      earningsConfig: {
        group: null,
        metric: {
          value: 'bids',
          label: this.$t('main.credits'),
        },
      },
      selectedRange: null,
      isLoading: false,
      isChartLoading: false,
      isSubIdsLoading: false,
      subId: null,
      shownDetail: false,
    };
  },
  watch: {
    selectedRange() {
      if (this.getSelectedRange !== this.selectedRange) {
        this.$store
          .dispatch('setFinancesSelectedRange', this.selectedRange)
          .then(() => {
            this.loadEarnings();
          });
      }
    },
    earningsConfig() {
      this.loadEarningsChart();
    },
  },
  computed: {
    defaultSubIdOption() {
      return {
        id: 'subId',
        label: this.$t('main.emptySubId'),
        value: null,
      };
    },
    earningsChartOptions() {
      return [
        {
          id: 'bids',
          value: 'bids',
          label: this.$t('main.credits'),
        },
        {
          id: 'cpm',
          value: 'cpm',
          label: 'CPM',
          suffix: this.$store.getters.getCurrency,
        },
        {
          id: 'cost',
          value: 'cost',
          label: this.$t('common.sum'),
          suffix: this.$store.getters.getCurrency,
        },
      ];
    },
    earningsLegend() {
      return this.earningsSorted.slice(0, 5);
    },
    getChartMetric() {
      const metric = this.$store.getters.getEarningsChartMetric;
      return metric
        ? this.earningsChartOptions.find(el => el.value === metric)
        : null;
    },
    getTotals() {
      return this.$store.getters.getEarnings
        && this.$store.getters.getEarnings.length
        ? this.$store.getters.getEarnings[
          this.$store.getters.getEarnings.length - 1
        ]
        : null;
    },
    getSelectedRange() {
      return this.$store.getters.getFinancesSelectedRange;
    },
    detailedRequestConfig() {
      const { start, end, group } = {
        ...this.selectedRange.value,
        ...this.earningsConfig,
      };
      return {
        url: 'earnings/',
        start,
        end,
        group,
        detailedModels: this.detailedModels,
      };
    },
    getEarnings() {
      return this.$store.getters.getEarnings || [];
    },
    getSubIds() {
      return this.$store.getters.getSubIds || [];
    },
    isSubIds() {
      return this.getSubIds.length > 0;
    },
    subIdsOptions() {
      return [this.defaultSubIdOption].concat(
        this.getSubIds.map((subId, index) => ({
          id: `subId-${index}`,
          label: `Sub id - ${subId}`,
          value: subId,
        })),
      );
    },
    /** Convert data form server to data for chart
     *
     */
    earningsChart() {
      const charts = [];
      const name = this.$store.getters.getEarningsChartMetric;
      // form the data
      this.$store.getters.getEarningsChart.forEach((el) => {
        let data = [];
        if (Array.isArray(this.$store.getters.getEarningsChart)) {
          data = el.data.map(cur => ({
            y: cur[name],
            x: moment(cur.ts).format('L LT'),
          }));
        }

        charts.push({
          id: el.id,
          country: el.country,
          name: this.countries[el.name] || el.name || this.$t('common.allWorld'),
          data,
        });
      });

      return charts;
    },
    sortedCharts() {
      const sortedCharts = [];
      let propName;

      switch (this.earningsConfig.group) {
        case 'country':
          propName = 'country';
          break;
        default:
          propName = 'id';
      }

      this.earningsLegend.forEach((element) => {
        const chart = this.earningsChart.find(
          el => el[propName] === element[propName],
        );
        if (chart) sortedCharts.push(chart);
      });

      return sortedCharts;
    },
    // Sort earnings by cost for carts, cause it's only one sort for cart
    earningsSorted() {
      // Copy by value and Removed total line
      const arr = [...this.$store.getters.getEarnings.slice(0, -1)];
      return arr.sort((a, b) => b.cost - a.cost);
    },
  },
  created() {
    const [firstPeriod] = this.customPeriodOptions;
    const [firstOption] = this.earningsModel.complex.options;
    this.selectedRange = firstPeriod;
    this.earningsConfig.group = firstOption.value.group;
    this.subId = this.defaultSubIdOption;
  },
  mounted() {
    if (this.getChartMetric) {
      this.earningsConfig.metric = this.getChartMetric;
    }

    this.loadSubIds();

    if (this.getSelectedRange && this.getSelectedRange.shouldReload) {
      this.selectedRange = this.getSelectedRange;
      this.selectedRange.shouldReload = false;
      this.loadEarnings();
    } else if (
      this.$store.getters.getEarningsGroup !== this.earningsModel.complex.options[0].value.group
    ) {
      if (this.getSelectedRange) this.selectedRange = this.getSelectedRange;
      this.loadEarnings();
    }

    if (!this.getSelectedRange) {
      this.loadEarnings();
      this.$store.dispatch('setFinancesSelectedRange', this.selectedRange);
    } else {
      this.selectedRange = this.getSelectedRange;
    }
  },
  beforeDestroy() {
    this.$store.dispatch('setFinancesSelectedRange', null);
  },
  methods: {
    formatNumberCompact,
    chartOptions,
    onHostDetailShown(state) {
      this.shownDetail = state;
    },
    /** Blya cto eto???????
     *
     * @param data
     * @return {number|*}
     */
    findMin(data) {
      try {
        return data.reduce((acc, cur) => {
          const temp = cur.data.reduce(
            (a, c) => (a < c.y ? a : c.y),
            cur.data[0].y,
          );
          return temp < acc ? temp : acc;
        }, data[0].data[0].y);
      } catch {
        return 0;
      }
    },
    findMax(data) {
      try {
        return data.reduce((acc, cur) => {
          const temp = cur.data.reduce(
            (a, c) => (a > c.y ? a : c.y),
            cur.data[0].y,
          );
          return temp > acc ? temp : acc;
        }, 0);
      } catch {
        return 0;
      }
    },
    loadEarnings() {
      const { start, end, group } = {
        ...this.selectedRange.value,
        ...this.earningsConfig,
      };
      this.isLoading = true;
      this.$store
        .dispatch('earningsRequest', {
          queryParams: {
            start,
            end,
            group,
            ...(!!this.subId.value
              && !this.shownDetail
              && this.earningsConfig.group !== 'country' && {
              subid: this.subId.value,
            }),
          },
        })
        .finally(() => {
          this.isLoading = false;
        });
      this.loadEarningsChart();
    },
    loadSubIds() {
      this.isSubIdsLoading = true;
      this.$store.dispatch('subIdsRequest').finally(() => {
        this.isSubIdsLoading = false;
      });
    },
    loadEarningsChart() {
      const {
        start, end, metric, group,
      } = {
        ...this.selectedRange.value,
        ...this.earningsConfig,
      };
      this.isChartLoading = true;
      this.$store
        .dispatch('earningsChartRequest', {
          queryParams: {
            start,
            end,
            metric: metric.value,
            group,
            ...(!!this.subId.value
              && !this.shownDetail
              && this.earningsConfig.group !== 'country' && {
              subid: this.subId.value,
            }),
          },
        })
        .finally(() => {
          this.isChartLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables";

.finances-page {
  &__select-range {
    text-align: right;
  }

  .chart-legend {
    padding: 20px 0 10px;

    &__element {
      padding: 5px;
    }

    &__marker {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 8px;
      margin: 0 2px 1px 0;
    }
  }

  .chart-controls {
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 2;
  }

  .stat-label {
    font-size: 1.1rem;
    color: $c-text-dimmed;
  }

  .stat-total {
    font-size: 1.6rem;
    line-height: 1.6;
  }

  .total-stats {
    margin-right: -20px;
    border-bottom: 1px solid $c-border-light;
  }
}
</style>
