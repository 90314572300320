export default {
  computed: {
    adlooxModel() {
      return {
        complex: {
          type: 'complex',
          width: 160,
          detailedQuery: true,
          options: [
            {
              id: 'platform',
              label: this.$t('main.platform'),
              value: {
                type: 'link',
                field: 'url',
                group: 'host',
                detailedKey: 'host',
                setNestedGroup: 'domain',
              },
            },
          ],
        },
        fraud: {
          label: '% Fraud (SIVT+GIVT)',
          type: 'percent-fraud',
          width: 120,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        givt: {
          label: '% GIVT',
          type: 'percent',
          width: 120,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        botnet: {
          label: 'Botnet',
          width: 120,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        fake_domain: {
          label: 'Fake Domain',
          type: 'text',
          sortable: true,
          width: 100,
          summarize: true,
          filterable: true,
          filterType: 'extended',
        },
        iab_lists: {
          label: 'IAB Lists',
          type: 'number',
          width: 120,
          sortable: true,
          summarize: true,
          filterable: true,
          filterType: 'extended',
        },
        known_datacenter: {
          label: 'Known Datacenter',
          type: 'number',
          width: 120,
          sortable: true,
          summarize: true,
          filterable: true,
          filterType: 'extended',
        },
        sivt: {
          label: '% SIVT',
          type: 'percent',
          width: 120,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        adware: {
          label: 'Adware',
          type: 'number',
          width: 120,
          sortable: true,
          summarize: true,
          filterable: true,
          filterType: 'extended',
        },
        domain_spoofing: {
          label: 'Domain Spoofing',
          type: 'number',
          sortable: true,
          width: 105,
          summarize: true,
          filterable: true,
          filterType: 'extended',
        },
        bad_autorefresh: {
          label: 'Bad Autorefresh',
          type: 'number',
          width: 120,
          sortable: true,
          summarize: false,
          hidden: true,
        },
        bad_external_traffic: {
          label: 'Bad External Traffic',
          type: 'number',
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        high_ad_density: {
          label: 'High Ad Density',
          type: 'number',
          width: 80,
          sortable: true,
          summarize: true,
          filterable: true,
          filterType: 'extended',
        },
        ghost_ad_call: {
          label: 'Ghost Ad Call',
          type: 'number',
          width: 80,
          sortable: true,
          summarize: true,
          filterable: true,
          filterType: 'extended',
        },
        inefficient: {
          label: '% Inefficient Traffic',
          type: 'percent',
          width: 130,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        autorefresh: {
          label: 'Autorefresh',
          type: 'number',
          width: 115,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        high_external_traffic: {
          label: 'High External Traffic',
          type: 'number',
          width: 115,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        high_frequency: {
          label: 'High Frequency',
          type: 'number',
          width: 115,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        low_visi: {
          label: 'Low Visi Measured',
          type: 'number',
          width: 115,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
        early_autorefresh: {
          label: 'Early Autorefresh',
          type: 'number',
          width: 115,
          sortable: true,
          summarize: false,
          filterable: true,
          filterType: 'extended',
        },
      };
    },
  },
};
