<template>
  <div class="audit-page pb-3">
    <div class="controls-row row mb-4" style="text-align: right">
      <div class="col finances-page__select-range">
        <CustomSelect
          type="radiogroup"
          :disabled="isLoading"
          :options="customPeriodOptions"
          :selected.sync="selectedRange"
        />
      </div>
    </div>
    <Pane class="mt-4">
      <Loader :visible="isLoading" />
      <DataGrid
        :data="adlooxData"
        :model="adlooxModel"
        :detailedRequestConfig="detailedRequestConfig"
        :expansible="true"
        :detailedExpansible="true"
        :count-total="true"
      />
    </Pane>
  </div>
</template>

<script>
import Pane from '@/components/decorative/Pane.vue';
import DataGrid from '@/components/decorative/DataGrid.vue';
import Loader from '@/components/decorative/Loader.vue';
import adlooxModel from '@/configs/table-models/adloox';
import detailedModels from '@/configs/table-models/adlooxDetailed';
import customPeriodOptions from '@/configs/customPeriodOptionsAdloox';
import CustomSelect from '@/components/controls/CustomSelect.vue';

export default {
  name: 'Adloox',
  mixins: [customPeriodOptions, adlooxModel, detailedModels],
  components: {
    Pane,
    DataGrid,
    Loader,
    CustomSelect,
  },
  data() {
    return {
      selectedRange: null,
      earningsConfig: {
        group: null,
      },
      isLoading: false,
    };
  },
  computed: {
    adlooxData() {
      return this.$store.getters.getAdlooxData || [];
    },
    detailedRequestConfig() {
      const { start, end, group } = {
        ...this.selectedRange.value,
        ...this.earningsConfig,
      };
      return {
        url: 'adloox/',
        start,
        end,
        group,
        detailedModels: this.detailedModels,
      };
    },
  },
  created() {
    const [defaultPeriod] = this.customPeriodOptions;
    const [firstOption] = this.adlooxModel.complex.options;
    this.earningsConfig.group = firstOption.value.group;
    this.selectedRange = defaultPeriod;
    this.loadAdloox();
  },
  methods: {
    loadAdloox() {
      this.isLoading = true;
      const { start, end } = this.selectedRange.value;
      this.$store
        .dispatch('adlooxRequest', {
          queryParams: {
            start,
            end,
            group: 'host',
            metric_type: 'relative',
          },
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    selectedRange(newSelectedRange, oldSelectedRange) {
      if (newSelectedRange !== oldSelectedRange) {
        this.loadAdloox();
      }
    },
  },
};
</script>
