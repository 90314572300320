export default {
  computed: {
    detailedModels() {
      return {
        country: {
          url: {
            label: this.$t('main.platform'),
            type: 'common',
            width: 160,
          },
          bids: {
            type: 'common',
            label: this.$t('main.credits'),
            width: 100,
            sortable: true,
          },
          cost: {
            type: 'common',
            label: this.$t('common.sumCurrency', { currency: this.$store.getters.getCurrency }),
            width: 120,
            sortable: true,
          },
          cpm: {
            type: 'common',
            label: `CPM, ${this.$store.getters.getCurrency}`,
            width: 110,
            sortable: true,
          },
        },
        host: {
          complex: {
            type: 'complex',
            fixed: true,
            width: 160,
            options: [
              {
                id: 'country',
                label: this.$t('common.country'),
                value: {
                  type: 'country',
                  field: 'country',
                  group: 'country',
                },
              },
              {
                id: 'day',
                label: this.$t('calendar.day'),
                value: {
                  type: 'date',
                  field: 'ts_day',
                  group: 'ts_day',
                },
              },
              {
                id: 'month',
                label: this.$t('calendar.month'),
                value: {
                  type: 'month',
                  field: 'ts_month',
                  group: 'ts_month',
                },
              },
            ],
          },
          bids: {
            type: 'common',
            label: this.$t('main.credits'),
            width: 100,
            sortable: true,
          },
          cost: {
            type: 'common',
            label: this.$t('common.sumCurrency', { currency: this.$store.getters.getCurrency }),
            width: 120,
            sortable: true,
          },
          cpm: {
            type: 'common',
            label: `CPM, ${this.$store.getters.getCurrency}`,
            width: 110,
            sortable: true,
          },
        },
      };
    },
  },
};
